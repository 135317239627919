<template>
  <div style="display: flex">
  <click-to-edit
    v-model="localValue"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.parent_id')"
    style="flex: 1 1 auto; margin-right: 5px; overflow: hidden; overflow-wrap: anywhere"
    type="select"
    select-filterable
    :select-options="scaleTemplates"
    select-option-id="id"
    select-option-name="name"
    @opened="remoteMethod"
    @input="saveElementPropChange(scope.row, 'scale', {parent_id:$event})"
  >
  </click-to-edit>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import requestSender from "@/api/base/requestSender";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-scale-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {
    targetIndex: {type: String, required: true}
  },
  computed: {
    description() {
      return this.scope.row?.scale?.parent?.description;
    }
  },
  data() {
    return {
      localValue: null,
      scaleTemplates: [],
    }
  },
  mounted() {
    if( this.scope.row.scale.parent ){
      this.scaleTemplates.push(this.scope.row.scale.parent);
      this.localValue = this.scope.row.scale.parent.id;
    }
  },
  methods: {
    remoteMethod() {
      requestSender('get', 'scale/templates-list', {
        sort: 'name',
      })
        .then(data => {
          this.scaleTemplates = data.scales;
        })
    },
  }
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

.open-desc-button {
  color: gray;

&.has-desc{
   color: $--color-primary;
 }
}
</style>
